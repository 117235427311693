
import { defineComponent, ref } from 'vue';

import { Modal } from 'ant-design-vue';
import type { UploadProps } from 'ant-design-vue';

import MediaCards from '@/components/cards/MediaCards.vue';
import TableList from '@/components/tables/TableList.vue';
import axios from '@/utils/axios';
import { getVideoUrl } from '@/utils/mediaUrl';

const columns = [
  {
    title: 'facePartUrl',
    dataIndex: 'facePartUrl',
    key: 'facePartUrl',
    width: 200,
  },
  {
    title: 'mediaUrl',
    dataIndex: 'mediaUrl',
    key: 'mediaUrl',
    width: 200,
  },
  {
    title: '진행상황',
    dataIndex: 'progress',
    key: 'progress',
    width: 200,
  },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    TableList,
    MediaCards,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageFileList = ref<UploadProps['fileList']>([]);
    const mediaCardRef = ref();
    const tableListRef = ref();

    const text = ref('');
    const isLoading = ref(false);

    const onClick = async () => {
      const { api, image, params, video } = await mediaCardRef.value.submit();
      isLoading.value = true;

      const newParams = params.value.reduce(
        (
          acc: { [key: string]: string },
          curr: { key: string; value: number }
        ) => {
          // if (curr.key === 'faceScale') {
          //   return { ...acc, [curr.key]: curr.value / 100 };
          // }
          if (curr.key === 'faceOffsetX') {
            return { ...acc, [curr.key]: curr.value };
          }
          return { ...acc, [curr.key]: curr.value };
        },
        {}
      );

      axios
        .post(api, {
          mediaId: Number(image?.[0].uid || video?.[0].uid),
          // mediaId: 14,
          firstFrame: false,
          ...newParams,
          // faceScale: 0,
          // faceOffsetX: 90,
          // faceOffsetY: 0,
          // maskBlue: 150,
          // maskErode: 90,
          // superResolution: 70,
        })
        .then((res) => {
          Modal.info({
            title: '요청 성공',
            content: '요청 성공했어요.',
          });
          tableListRef.value.getApiData();

          isLoading.value = false;
        })
        .catch((error) => {
          Modal.error({
            title: '요청 실패',
            content: '관리자에게 문의해보세요.',
          });
          isLoading.value = false;
        });
    };

    // onMounted(() => {
    //   console.log('123123');
    // });

    return {
      columns,
      // state
      mediaCardRef,
      tableListRef,
      imageFileList,
      text,
      isLoading,
      // func
      getVideoUrl,
      onClick,
    };
  },
});
