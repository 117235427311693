import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  class: "animation",
  style: {"position":"absolute","display":"flex","justify-content":"center","align-items":"center","left":"0","top":"0","width":"100%","height":"100%","background":"rgba(0, 0, 0, 0.5)"}
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlayCircleTwoTone = _resolveComponent("PlayCircleTwoTone")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{"position":"relative","width":"100%","min-height":"100px","max-height":"200px","height":"100%","border-radius":"10px","overflow":"hidden","display":"flex","align-items":"center","justify-content":"center"}, { border: !_ctx.src ? '1px solid #ff4d4f' : 'none' }])
  }, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: {"position":"relative","width":"100%","height":"100%","border-radius":"10px","overflow":"hidden"},
          onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHover = true)),
          onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHover = false)),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.isActiveModal && _ctx.isActiveModal(...args)))
        }, [
          _createElementVNode("video", {
            src: _ctx.src,
            style: {"width":"100%","height":"100%","object-fit":"cover"}
          }, null, 8, _hoisted_1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_PlayCircleTwoTone, { style: {"font-size":"40px"} })
          ])
        ], 32))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_CloseCircleOutlined, { style: {"font-size":"30px","color":"#ff4d4f","display":"flex","align-self":"center"} })
        ])),
    _createVNode(_component_a_modal, {
      visible: _ctx.isActive,
      footer: null,
      closable: false,
      centered: "",
      "destroy-on-close": "",
      onCancel: _cache[3] || (_cache[3] = 
        () => {
          _ctx.isActive = false;
        }
      )
    }, {
      default: _withCtx(() => [
        _createElementVNode("video", {
          src: _ctx.src,
          style: {"width":"100%","height":"100%","object-fit":"cover"},
          playsinline: "",
          controls: ""
        }, null, 8, _hoisted_4)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 4))
}