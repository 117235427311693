
import { defineComponent, ref } from 'vue';

const formatter = (value: number) => {
  return `${value}%`;
};

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['click', 'sub'],
  setup(props, { emit }) {
    const makeupParamOptions = ref([
      {
        key: 'faceScale',
        label: 'faceScale',
        value: 0,
        min: -10,
        max: 10,
        unit: '%',
      },
      {
        key: 'faceOffsetX',
        label: 'faceOffsetX',
        value: 0,
        min: -100,
        max: 100,
        unit: 'px',
      },
      {
        key: 'faceOffsetY',
        label: 'faceOffsetY',
        value: 0,
        min: -100,
        max: 100,
        unit: 'px',
      },
      {
        key: 'maskBlur',
        label: 'maskBlur',
        value: 0,
        min: 0,
        max: 100,
        unit: 'px',
      },
      {
        key: 'maskErode',
        label: 'maskErode',
        value: 0,
        min: 0,
        max: 100,
        unit: 'px',
      },
      {
        key: 'superResolution',
        label: 'superResolution',
        value: 0,
        min: 0,
        max: 100,
        unit: '%',
      },
    ]);
    const onSubmit = () => {
      return {
        api: 'change/',
        params: makeupParamOptions,
      };
    };

    const onClick = () => {
      emit('sub');
    };

    return {
      makeupParamOptions,
      // state

      // func
      formatter,
      onClick,
      onSubmit,
    };
  },
});
