
import { computed, defineComponent, ref } from 'vue';

import { UploadOutlined, PlusOutlined } from '@ant-design/icons-vue';
import type { UploadProps } from 'ant-design-vue';

import axios from '@/utils/axios';

import { IMediaFile } from '../cards/MediaCard.vue';

function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default defineComponent({
  components: {
    UploadOutlined,
    PlusOutlined,
  },
  props: {
    fileList: {
      type: Array as () => IMediaFile[],
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    mediaType: {
      type: String,
      required: false,
      default: 'image',
    },
    isTemp: {
      type: Boolean,
      required: false,
      default: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const previewVisible = ref<boolean>(false);
    const previewMedia = ref<string | undefined>('');

    const resultType = ref('a-image');

    const onChange: UploadProps['onChange'] = (info) => {
      const newFileList = info.fileList.map((obj) => {
        return obj;
      });
      emit('change', { fileList: newFileList });
    };

    const handlePreview: UploadProps['onPreview'] = async (file) => {
      if ((file.type?.indexOf('image') || 0) > -1) {
        resultType.value = 'a-image';
      } else {
        resultType.value = 'video';
      }
      console.log(file);

      if (!file.originFileObj) {
        previewMedia.value = file.url;
      } else {
        previewMedia.value = (await getBase64(file.originFileObj)) as string;
      }
      previewVisible.value = true;
    };

    // const beforeUpload: UploadProps['beforeUpload'] = () => false;

    const handleCancel = () => {
      previewVisible.value = false;
    };

    const beforeUpload2: UploadProps['customRequest'] = async ({
      onSuccess,
      onError,
      file,
    }) => {
      if (file instanceof File) {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('fileName', file.name.normalize());

        const getApiUrl = () => {
          switch (true) {
            case file.type.indexOf('image') > -1:
              return '/image/';
            case file.type.indexOf('video') > -1:
              return '/video/';
            default:
              return '/audio/';
          }
        };
        axios
          .post(getApiUrl(), formData)
          .then((res) => {
            if (res.data) {
              onSuccess?.({ ...res.data, originFileObj: file });
            }
          })
          .catch((err) => {
            alert(err.response.data.message);
            const error = new Error(err.response.data.message);
            onError?.(error);
          });
      }
    };

    const getComponentProps = computed(() => {
      return {
        listType: 'picture',
        accept: `video/*, image/*`,
        componentName: resultType.value,
      };
    });

    return {
      // state
      previewVisible,
      previewMedia,

      // func
      onChange,
      handlePreview,
      // beforeUpload,
      beforeUpload2,
      handleCancel,
      // computed
      getComponentProps,
    };
  },
});
