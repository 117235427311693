
export interface IMediaFile extends UploadFile {
  name: string;
  message?: string;
  faces?: string | string[];
}

import { defineComponent, ref } from 'vue';

import type { UploadFile, UploadProps } from 'ant-design-vue';

import OriginalMediaSearch from '../OriginalMediaSearch.vue';
import Upload from '../uploads/Upload.vue';

export default defineComponent({
  name: 'MediaCard',
  components: { OriginalMediaSearch, Upload },
  props: {
    mediaType: {
      type: String,
      required: false,
      default: 'image',
    },
    title: {
      type: String,
      required: false,
      default: 'Image',
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploaderProps: {
      type: Object as () => {
        isTemp: boolean;
        disabled?: boolean;
        max: number;
        min?: number;
      },
      required: false,
      default: () => {
        return { isTemp: true, max: 1, min: 1 };
      },
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const mediaUploadRef = ref();
    const fileList = ref<IMediaFile[]>([]);
    const disabled = ref(true);

    const onChange: UploadProps['onChange'] = (info) => {
      fileList.value = info.fileList;
      emit('change', info);
    };

    const onSelect = (option: {
      id: number;
      name: string;
      label: string;
      value: string;
      type: string;
    }) => {
      fileList.value = [
        {
          uid: String(option.id),
          name: option.label,
          status: 'done',
          type: option.type,
          url: option.value,
          thumbUrl: option.value,
        },
      ];
      emit('change', { fileList: fileList.value });
    };

    const onSubmit = async () => {
      // const response = fileList.value.map(obj => obj.response)
      return { [props.mediaType]: fileList.value };
    };

    return {
      // state
      disabled,
      fileList,
      mediaUploadRef,
      // func
      onChange,
      onSelect,
      onSubmit,
    };
  },
});
