
import { defineComponent, ref } from 'vue';

import axios from '@/utils/axios';
import { getImageUrl } from '@/utils/mediaUrl';

interface Option {
  value: string;
  label: string;
}
export default defineComponent({
  props: {
    type: {
      type: String,
      required: false,
      default: 'image',
    },
    placeholder: {
      type: String,
      required: false,
      default: '검색',
    },
  },
  emits: ['select'],
  setup(props, { emit }) {
    const inputValue = ref('');
    const options = ref<Option[]>([]);

    const onSelect = (val: string, option: Option) => {
      inputValue.value = '';
      console.log(option);
      emit('select', option);
    };

    const onChange = () => {
      options.value = [];
    };

    const onSearch = async () => {
      const { data: videoData } = await axios.get('/videos', {
        params: { partialName: inputValue.value },
      });

      options.value = videoData.map(
        (obj: { id: number; name: string; url: string }) => {
          return {
            id: obj.id,
            label: obj.name,
            type: 'video',
            value: obj.url,
          };
        }
      );

      const { data } = await axios.get('/images', {
        params: { partialName: inputValue.value },
      });
      options.value = [
        ...options.value,
        ...data.map((obj: { id: number; name: string; url: string }) => {
          return {
            id: obj.id,
            label: obj.name,
            type: 'image',
            value: obj.url,
          };
        }),
      ];
    };
    return {
      getImageUrl,
      // state
      inputValue,
      options,
      // func
      onChange,
      onSearch,
      onSelect,
    };
  },
});
