
import {
  computed,
  defineComponent,
  onBeforeMount,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from 'vue';

import { SyncOutlined } from '@ant-design/icons-vue';
import { useCookies } from 'vue3-cookies';

import VideoModal from '@/components/medias/VideoModal.vue';
import axios from '@/utils/axios';
import { getAudioUrl, getVideoUrl, getImageUrl } from '@/utils/mediaUrl';

interface ITableColumn {
  title: string;
  key: string;
  dataIndex?: string;
  width?: number;
}

interface IListColumn {
  title: string;
  key: string;
}

const pagination = {
  pageSize: 30,
  simple: true,
};

const commonColumns: ITableColumn[] | undefined = [
  {
    title: '생성일자',
    key: 'created',
    dataIndex: 'created',
    width: 200,
  },
  // {
  //   title: '생성일',
  //   key: 'timestamp',
  //   dataIndex: 'timestamp',
  //   width: 200,
  // },
];

export default defineComponent({
  name: 'UploadImage',
  components: {
    VideoModal,
    SyncOutlined,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: 'video',
    },
    columns: {
      type: Array as () => ITableColumn[] | [],
      required: true,
    },
    apis: {
      type: Object as () => {
        list: string;
        search?: string;
        searchTag?: string;
      },
      required: true,
    },
    listColumns: {
      type: Array as () => IListColumn[],
      required: false,
      default: () => [],
    },
    tableHandlerProps: {
      type: Object as () => {
        mode: string[];
      },
      required: false,
      default: () => {
        return {
          mode: ['download', 'delete', 'upload'],
        };
      },
    },
  },
  setup(props) {
    const state = reactive({
      isMine: true,
      listType: true,
    });
    const apiData = ref([]);
    const total = ref(0);
    const selected = ref<
      {
        token: string;
        name?: string;
        filename?: string;
      }[]
    >([]);

    const time = ref(9);

    const intervalTimerId = ref();

    const current = ref(1);
    const isLoading = ref<boolean>(false);

    const onClickRefresh = async () => {
      isLoading.value = true;
      time.value = 10;
      await getApiData();
      isLoading.value = false;
    };

    watch(current, async () => {
      isLoading.value = true;
      await getApiData();
      isLoading.value = false;
    });

    const reduceEverySecond = () => {
      intervalTimerId.value = setInterval(() => {
        if (time.value <= 1) {
          time.value = 10;
        } else {
          time.value -= 1;
        }
      }, 1000);
    };

    watch(time, async () => {
      if (time.value === 10) {
        isLoading.value = true;
        await getApiData();
        isLoading.value = false;
      }
    });

    onUnmounted(() => {
      clearInterval(intervalTimerId.value);
    });

    const onClickReload = (e: Event) => {
      const checked = (e.target as HTMLInputElement).checked;
      if (checked) {
        reduceEverySecond();
      } else {
        clearInterval(intervalTimerId.value);
        console.log(intervalTimerId.value);
      }
    };

    const getApiData = async () => {
      return new Promise((resolve) => {
        console.log('test');
        const { cookies } = useCookies();
        const accessToken = cookies.get('accessToken');

        axios
          .get(`${props.apis.list}`, {
            params: { page: current.value, size: 10 },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(({ data }) => {
            time.value = 10;
            isLoading.value = false;
            apiData.value = data.data;
            total.value = data.total;
            resolve(data);
          });
      });
    };

    const onUpload = () => {
      getApiData();
    };

    const getDate = computed(() => {
      return (date: string) => {
        const d = new Date(date);
        const getMonth = () => {
          const month = d.getMonth() + 1;
          return month < 10 ? `0${month}` : month;
        };

        const getDay = () => {
          const day = d.getDate();
          return day < 10 ? `0${day}` : day;
        };

        const getHour = () => {
          const hour = d.getHours();
          return hour < 10 ? `0${hour}` : hour;
        };

        const getTime = () => {
          const time = d.getMinutes();
          return time < 10 ? `0${time}` : time;
        };

        const getSeconds = () => {
          const seconds = d.getSeconds();
          return seconds < 10 ? `0${seconds}` : seconds;
        };

        return `${d.getFullYear()}-${getMonth()}-${getDay()} ${getHour()}:${getTime()}:${getSeconds()}`;
      };
    });

    const isImageFormat = computed(() => {
      return (url: string) =>
        url?.split('.').pop() === 'jpg' ||
        url?.split('.').pop() === 'png' ||
        url?.split('.').pop() === 'jpeg';
    });

    const isVideoFormat = computed(() => {
      return (url: string) => {
        return (
          url.split('.').pop() === 'mp4' || url.split('.').pop() === 'webm'
        );
      };
    });

    const onChangePagination = (i: number) => {
      current.value = i;
    };

    onBeforeMount(async () => {
      getApiData();
    });

    return {
      ...toRefs(state),
      time,
      current,
      total,
      isLoading,
      apiData,
      mergedColumns: [...props.columns, ...commonColumns],
      // getCreator,
      selected,
      pagination,
      // funcs
      getAudioUrl,
      getVideoUrl,
      getImageUrl,
      onUpload,
      onChangePagination,
      onClickRefresh,
      onClickReload,
      // computed
      getApiData,
      getDate,
      isImageFormat,
      isVideoFormat,
    };
  },
});
