
import { computed, defineComponent, ref } from 'vue';
import type { Component } from 'vue';

import type { UploadChangeParam, UploadFile } from 'ant-design-vue';

import ControlCard from './ControlCard.vue';
import ImageMappingCard from './ImageMappingCard.vue';
import MediaCard from './MediaCard.vue';

type tChild = Array<
  | ({ onSubmit?: () => void | Promise<any>; disabled?: boolean } & {
      uploaderProps: {
        isTemp: boolean;
        max: number;
        min: number;
      };
      fileList?: (UploadFile & { faces?: string })[];
      min: number;
    } & Component)
  | undefined
  | null
>;

export default defineComponent({
  components: {
    ControlCard,
    MediaCard,
    ImageMappingCard,
  },
  props: {
    type: {
      type: Array as () => {
        name: string;
        props?: { [key: string]: any };
        span?: number;
        event?: {
          [key: string]: (
            info?: UploadChangeParam<UploadFile<unknown>>
          ) => void;
        };
      }[],
      required: true,
    },
  },
  setup(props) {
    const childrenRef = ref<tChild>([]);

    const imageTokens = ref<string[]>([]);

    const loadFace = () => {
      const faces = childrenRef.value[0]?.fileList?.[0].faces;

      if (typeof faces === 'string') {
        imageTokens.value = JSON.parse(faces);
      }
      if (Array.isArray(faces)) {
        imageTokens.value = faces;
      }
    };

    const getCardComponent = computed(() => {
      return props.type.map(({ name, props, event }) => {
        if (name === 'scenario') {
          return {
            name: 'ScenarioCard',
            props: {
              ...props,
              disabled: getDisableSubmit.value,
            },
            event,
          };
        }

        if (name === 'image' || name === 'video' || name === 'audio') {
          return {
            name: 'MediaCard',
            props: {
              ...props,
              title: name.charAt(0).toUpperCase() + name.slice(1),
              mediaType: name,
            },
            event,
          };
        }

        if (name === 'ImageMappingCard' || name === 'ImageCheckCard') {
          return {
            name,
            props: {
              ...props,
              imageTokens: imageTokens.value,
              disabled: childrenRef.value[0]?.fileList?.length !== 1,
            },
            event: {
              ...event,
              click: loadFace,
            },
          };
        }

        return {
          name,
          props: {
            ...props,
            disabled: childrenRef.value[0]?.fileList?.length !== 1,
          },
          event,
        };
      });
    });

    const submit = async () => {
      const mergedValues = await childrenRef.value.reduce(async (acc, curr) => {
        return { ...(await acc.then()), ...(await curr?.onSubmit?.()) };
      }, Promise.resolve({}));

      return mergedValues;
    };

    const getDisableSubmit = computed(() => {
      return !childrenRef.value.every((ref) => {
        if (!ref?.fileList) {
          return true;
        }
        if (
          ref.fileList?.filter(({ status }) => status !== 'done').length > 0
        ) {
          return false;
        }
        return ref.fileList?.length >= (ref?.uploaderProps?.min || 1);
      });
    });

    return {
      childrenRef,
      imageTokens,
      // func
      submit,
      // computed
      getCardComponent,
      getDisableSubmit,
    };
  },
});
