
import { defineComponent, ref } from 'vue';

import { getImageUrl } from '@/utils/mediaUrl';

import { IMediaFile } from './MediaCard.vue';

export default defineComponent({
  props: {
    src: {
      type: String,
      required: false,
      default: undefined,
    },
    imageTokens: {
      type: Array as () => string[],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const faceTokens = ref<string[]>([]);
    const loadFace = () => {
      emit('click');
    };

    const onChangeTargetImage = (
      info: { fileList: IMediaFile[] },
      index: number
    ) => {
      if (info.fileList.length === 0) {
        faceTokens.value[index] = '';
      } else {
        let faces = info.fileList[0].faces;
        if (typeof info.fileList[0].faces === 'string') {
          faces = JSON.parse(info.fileList[0].faces);
        }

        if (Array.isArray(faces)) {
          faceTokens.value[index] = faces[0];
        }
      }
    };

    const onSubmit = async () => {
      return {
        convert_target_faces: faceTokens.value,
        convert_face_tokens: props.imageTokens,
      };
    };

    return {
      getImageUrl,
      // funcs
      loadFace,
      onChangeTargetImage,
      onSubmit,
    };
  },
});
